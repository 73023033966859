<ng-container *ngIf="title">
  <ae2-dialog-header [title]="title"></ae2-dialog-header>
</ng-container>

<ng-container *ngIf="instructors">
  <mat-dialog-content>
    <ng-container *ngIf="instructor">
      <p class="ae2-font">
        {{ instructor?.nickname ? instructor?.nickname : instructor?.firstName }}
       <a [routerLink]="['/professores', instructor?.slug, instructor?.id]" (click)="ok()"> (more) </a>
     </p>
    </ng-container>
    <p class="ae2-font" *ngFor="let item of instructors">
      {{ item?.nickname ? item?.nickname : item?.firstName }}
      <a [routerLink]="['/professores', item?.slug, item.id]" (click)="ok()"> (more) </a> <br>
    </p>
  </mat-dialog-content>
</ng-container>

<mat-dialog-actions fxLayout fxLayoutGap="10px" fxLayoutAlign="end center">
  <button mat-raised-button (click)="ok()">{{ okButtonLabel }}</button>
</mat-dialog-actions>
