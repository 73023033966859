/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angularecommerce/core/components/dialog-header/dialog-header.component.ngfactory";
import * as i2 from "@angularecommerce/core/components/dialog-header/dialog-header.component";
import * as i3 from "@angular/material/dialog";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "@angular/flex-layout/flex";
import * as i7 from "@angular/flex-layout/core";
import * as i8 from "@angular/cdk/bidi";
import * as i9 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i10 from "@angular/material/button";
import * as i11 from "@angular/cdk/platform";
import * as i12 from "@angular/cdk/a11y";
import * as i13 from "@angular/platform-browser/animations";
import * as i14 from "./instructor-dialog.component";
var styles_Ae2InstructorDialogComponent = [];
var RenderType_Ae2InstructorDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_Ae2InstructorDialogComponent, data: {} });
export { RenderType_Ae2InstructorDialogComponent as RenderType_Ae2InstructorDialogComponent };
function View_Ae2InstructorDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "ae2-dialog-header", [], [[2, "ae2-dialog-header", null]], null, null, i1.View_Ae2DialogHeaderComponent_0, i1.RenderType_Ae2DialogHeaderComponent)), i0.ɵdid(2, 49152, null, 0, i2.Ae2DialogHeaderComponent, [i3.MatDialogRef], { title: [0, "title"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); }); }
function View_Ae2InstructorDialogComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "p", [["class", "ae2-font"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵeld(3, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.ok() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(5, 3), (_l()(), i0.ɵted(-1, null, [" (more) "]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 5, 0, "/professores", ((_co.instructor == null) ? null : _co.instructor.slug), ((_co.instructor == null) ? null : _co.instructor.id)); _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.instructor == null) ? null : _co.instructor.nickname) ? ((_co.instructor == null) ? null : _co.instructor.nickname) : ((_co.instructor == null) ? null : _co.instructor.firstName)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 4).target; var currVal_2 = i0.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_1, currVal_2); }); }
function View_Ae2InstructorDialogComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "p", [["class", "ae2-font"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), (_l()(), i0.ɵeld(2, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.ok() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(4, 3), (_l()(), i0.ɵted(-1, null, [" (more) "])), (_l()(), i0.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var currVal_3 = _ck(_v, 4, 0, "/professores", ((_v.context.$implicit == null) ? null : _v.context.$implicit.slug), _v.context.$implicit.id); _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.nickname) ? ((_v.context.$implicit == null) ? null : _v.context.$implicit.nickname) : ((_v.context.$implicit == null) ? null : _v.context.$implicit.firstName)); _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 3).target; var currVal_2 = i0.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_1, currVal_2); }); }
function View_Ae2InstructorDialogComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(2, 16384, null, 0, i3.MatDialogContent, [], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_Ae2InstructorDialogComponent_3)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_Ae2InstructorDialogComponent_4)), i0.ɵdid(6, 802816, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.instructor; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.instructors; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_Ae2InstructorDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_Ae2InstructorDialogComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_Ae2InstructorDialogComponent_2)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 7, "mat-dialog-actions", [["class", "mat-dialog-actions"], ["fxLayout", ""], ["fxLayoutAlign", "end center"], ["fxLayoutGap", "10px"]], null, null, null, null, null)), i0.ɵdid(5, 16384, null, 0, i3.MatDialogActions, [], null, null), i0.ɵdid(6, 737280, null, 0, i6.LayoutDirective, [i7.MediaMonitor, i0.ElementRef, i7.StyleUtils], { layout: [0, "layout"] }, null), i0.ɵdid(7, 1785856, null, 0, i6.LayoutGapDirective, [i7.MediaMonitor, i0.ElementRef, [6, i6.LayoutDirective], i0.NgZone, i8.Directionality, i7.StyleUtils], { gap: [0, "gap"] }, null), i0.ɵdid(8, 737280, null, 0, i6.LayoutAlignDirective, [i7.MediaMonitor, i0.ElementRef, [6, i6.LayoutDirective], i7.StyleUtils], { align: [0, "align"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 2, "button", [["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ok() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i0.ɵdid(10, 180224, null, 0, i10.MatButton, [i0.ElementRef, i11.Platform, i12.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(11, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.instructors; _ck(_v, 3, 0, currVal_1); var currVal_2 = ""; _ck(_v, 6, 0, currVal_2); var currVal_3 = "10px"; _ck(_v, 7, 0, currVal_3); var currVal_4 = "end center"; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = (i0.ɵnov(_v, 10).disabled || null); var currVal_6 = (i0.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_5, currVal_6); var currVal_7 = _co.okButtonLabel; _ck(_v, 11, 0, currVal_7); }); }
export function View_Ae2InstructorDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-instructor-dialog", [], null, null, null, View_Ae2InstructorDialogComponent_0, RenderType_Ae2InstructorDialogComponent)), i0.ɵdid(1, 114688, null, 0, i14.Ae2InstructorDialogComponent, [i3.MatDialogRef, i3.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Ae2InstructorDialogComponentNgFactory = i0.ɵccf("app-instructor-dialog", i14.Ae2InstructorDialogComponent, View_Ae2InstructorDialogComponent_Host_0, {}, {}, []);
export { Ae2InstructorDialogComponentNgFactory as Ae2InstructorDialogComponentNgFactory };
