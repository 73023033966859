import {Subject} from 'rxjs/Subject';  
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

const heightMenuOffset = 110;

@Injectable()
export class AppScrollWatcherService {
 state: any = {};


 constructor(private router: Router) {
 }

  setScrollState(key: string, value: any, currentScroll: number): void {
    
    currentScroll += heightMenuOffset;

    this.state[key] = value;
    
    let mostLowerHash = '';
    let mostLowerValue = '';
    for (const i in this.state) {
      if (mostLowerValue < this.state[i].top && currentScroll >= this.state[i].top) {
        mostLowerValue = this.state[i].top;
        mostLowerHash = this.state[i].fragment;
      }
    }
    this.router.navigate([], {fragment: mostLowerHash});
  }

}
