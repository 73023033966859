<!-- <div class="newsletter-container">
  <div class="newsletter-title">
    <h2 class="newsletter-title-title">NEWSLETTER</h2>
    <div class="newsletter-title-subtitle">
      <p>RECEIVE OUR NEWSLETTER</p>
    </div>
  </div>

  <form [formGroup]="form" (submit)="submit()" novalidate autocomplete="off" class="news-form">
    <div fxLayout fxLayout.xs="column" fxLayoutGap="15px">
      <div fxFlex="calc(33% - 15px)" fxFlex.xs="auto">
        <mat-form-field class="full">
          <input type="text" id="news-name" class="input-name" matInput placeholder="NAME" formControlName="email" />
        </mat-form-field>
        <ae2-control-messages class="form-error" [control]="form.get('name')" #nameControl></ae2-control-messages>
      </div>
      <div fxFlex="calc(33% - 15px)" fxFlex.xs="auto">
        <mat-form-field class="full">
          <input type="text" id="news-email" class="input-email" matInput placeholder="E-MAIL" formControlName="email" />
        </mat-form-field>
        <ae2-control-messages class="form-error" [control]="form.get('email')" #emailControl></ae2-control-messages>
      </div>
      <div fxFlex="calc(33% - 15px)" fxFlex.xs="auto">
        <button mat-raised-button [disabled]="form.pending || form.invalid" class="power">SUBSCRIBE TO NEWSLETTER
          <img src="assets/images/footer/mail.svg" alt="mail">
        </button>
      </div>
    </div>
  </form>
</div> -->

<!-- <div class="parceiros">
  <h2 class="parceiros-title">PARCEIROS</h2>
  <div class="parceiros-images">
    <img src="assets/images/footer/parceiros/canno.png" alt="cannodale">
    <img src="assets/images/footer/parceiros/shimano.png" alt="shimano">
    <img src="assets/images/footer/parceiros/aws.png" alt="shimano" style="height: 90px;">
  </div>
</div> -->


<ae2-footer [logo]="'assets/images/logo2.png'">
  <ng-template #defaultTemplate let-settings>

    <footer class="ae2-footer">
      <div class="footer_bottom">
        <div class="logo-footer">
          <img src="assets/images/logo2.png" alt="powercycle">
        </div>


        <div class="footer_middle" fxLayout fxLayout.xs="column" fxLayoutAlign="space-between center">
          <div class="buttons_plans" fxFlex="33%" fxFlex.xs="auto">
            <button mat-raised-button routerLink="/schedule" class="power">BOOK EXPERIMENTAL CLASS
              <img src="assets/images/lightning.svg" alt="lightning"> </button>
          </div>

          <div class="buttons_plans" fxFlex="33%" fxFlex.xs="auto">
            <button mat-raised-button routerLink="/packs" class="power">BUY CREDITS
              <img src="assets/images/lightning.svg" alt="lightning">
            </button>
          </div>

          <div class="social" fxFlex="33%" fxFlex.xs="auto" fxLayout>
            <h3>FOLLOW
              <br>POWER
              <br>CYCLE</h3>

            <div class="redes">
              <div fxLayout>
                <div class="footer_botton_top_content_social" matLine *ngIf="settings.contactEmail" (click)="targetLint('mailto:'+settings.contactEmail)">
                  <i class="fa fa-envelope-o" aria-hidden="true"></i>
                </div>
                <div class="footer_botton_top_content_social" matLine *ngIf="settings.socialTwitter" (click)="targetLint(settings.socialTwitte)">
                  <i class="fa fa-twitter" aria-hidden="true"></i>
                </div>
                <div class="footer_botton_top_content_social" matLine *ngIf="settings.socialFacebook" (click)="targetLint(settings.socialFacebook)">
                  <i class="fa fa-facebook" aria-hidden="true"></i>
                </div>
                <div class="footer_botton_top_content_social" matLine *ngIf="settings.socialInstagram" (click)="targetLint(settings.socialInstagram)">
                  <i class="fa fa-instagram" aria-hidden="true"></i>
                </div>
                <div class="footer_botton_top_content_social" matLine *ngIf="settings.socialSpotify" (click)="targetLint(settings.socialSpotify)">
                  <i class="fa fa-spotify" aria-hidden="true"></i>
                </div>
                <div class="footer_botton_top_content_social" matLine *ngIf="settings.socialYoutube" (click)="targetLint(settings.socialYoutube)">
                  <i class="fa fa-youtube" aria-hidden="true"></i>
                </div>
                <div class="footer_botton_top_content_social" matLine *ngIf="settings.socialLinkedin" (click)="targetLint(settings.socialLinkedin)">
                  <i class="fa fa-linkedin" aria-hidden="true"></i>
                </div>
                <div class="footer_botton_top_content_social" matLine *ngIf="settings.socialVimeo" (click)="targetLint(settings.socialVimeo)">
                  <i class="fa fa-vimeo" aria-hidden="true"></i>
                </div>
                <div class="footer_botton_top_content_social" matLine *ngIf="settings.socialFlickr" (click)="targetLint(settings.socialFlickr)">
                  <i class="fa fa-flickr" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div fxLayout fxLayoutAlign="center" class="last-links">
          <p class="footer_botton_top_link" routerLink="/contact">CONTACT</p>
          <div>.</div>
          <a href="mailto:workwithus@powercycle.ae"><p class="footer_botton_top_link">WORK WITH US</p></a>
          <!-- <p class="footer_botton_top_link" routerLink="/work-with-us">TRABALHE CONOSCO</p> -->
        </div>


        <div class="ae2-footer_bottom">
          <span>Copyright © 2013-{{currentYear}}. All rights reserved</span>
          <span>
            <a href="http://angular-ecommerce.com" target="_blank" class="ae2-footer_bottom_powered">Powered by Angular
              e-Commerce</a>
          </span>
        </div>


      </div>
    </footer>
  </ng-template>
</ae2-footer>