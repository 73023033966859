import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { Ae2SiteSettingsModule} from '@angularecommerce/core/components/site-settings';
import { Ae2ReplacePipeModule } from '@angularecommerce/core/pipes/replace';
import { Ae2FooterModule } from '@angularecommerce/core/components/footer';
import { Ae2ControlMessagesModule } from '@angularecommerce/core/components/control-messages';
import { MatInputModule, MatButtonModule, MatListModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    ReactiveFormsModule,
    Ae2SiteSettingsModule,
    Ae2ReplacePipeModule,
    Ae2FooterModule,
    Ae2ControlMessagesModule,
    MatInputModule,
    MatButtonModule,
    MatListModule
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent]
})
export class FooterModule { }
