var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Ae2RouterClassesConfig } from '@angularecommerce/core/directives/router-classes';
var CustomRouterClassesConfig = /** @class */ (function (_super) {
    __extends(CustomRouterClassesConfig, _super);
    function CustomRouterClassesConfig() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cssClasses = ['pagesCustom'];
        _this.fragments = [
            'login',
            'categoria',
            'minha-conta',
            'checkout',
            'agenda',
            'evento',
            'atividades',
            'instrutor',
            'clientes',
            'faq',
            'termos',
            'politica',
            'depositions',
            'blog',
            'lp',
            'obrigado'
        ];
        return _this;
    }
    return CustomRouterClassesConfig;
}(Ae2RouterClassesConfig));
export { CustomRouterClassesConfig };
var HeaderModule = /** @class */ (function () {
    function HeaderModule() {
    }
    return HeaderModule;
}());
export { HeaderModule };
