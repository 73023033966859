import {
  Component, OnInit, OnDestroy, ChangeDetectorRef, ComponentRef,
  Input, ElementRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Ae2UnitSelectorService } from '@angularecommerce/core/services/unit-selector';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs/Subject';
import { Overlay, ScrollStrategy, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Ae2LocationModel } from '@angularecommerce/core/services/location';
import { Ae2UnitModel } from '@angularecommerce/core/services/units';
import { fromEvent } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { Ae2UnitSelectorState, ae2UnitSelectorStoreSelector } from '@angularecommerce/core/stores/unit-selector';
import { Ae2UnitSelectorSetEffectAction } from '@angularecommerce/core/stores/unit-selector/unit-selector-actions';

export class UnitSelectorGroup {
  location: Ae2LocationModel;
  units: Ae2UnitModel[];

  constructor(conf: UnitSelectorGroup) {
    Object.assign(this, conf);
  }
}



class CustomCloseScrollStrategy implements ScrollStrategy {

  enable(): void { }
  disable(): void { }

  attach(overlayRef: OverlayRef): void {
    fromEvent(window, 'scroll')
      // .take(1)
      .subscribe(() => {
        overlayRef.detach();
        overlayRef.dispose();
      });
  }
}

@Component({
  selector: 'app-unit-selector',
  templateUrl: './unit-selection.component.html',
  styleUrls: ['./unit-selection.component.sass']
})
export class UnitSelectorComponent implements OnInit, OnDestroy {

  selectedUnit: Ae2UnitModel;

  unitGroup: UnitSelectorGroup[];

  isChangeLocation: boolean = false;

  private overlayRef: OverlayRef;

  private unitSelectorStore$: Observable<Ae2UnitSelectorState>;

  private destroy$: Subject<any> = new Subject();

  constructor(
    private router: Router,
    private ae2UnitSelectorService: Ae2UnitSelectorService,
    private store: Store<any>,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.unitSelectorStore$ = store.pipe(select(ae2UnitSelectorStoreSelector));
  }

  ngOnInit(): void {
    this.unitSelectorStore$
      .pipe(
        takeUntil(this.destroy$),
        filter(state => Boolean(state))
      )
      .subscribe(state => {
        this.selectedUnit = state.unit;
        this.changeDetectorRef.markForCheck();
      });
    this.creatGroup();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
  }

  setData(overlayRef: OverlayRef): void {
    this.overlayRef = overlayRef;
  }

  creatGroup(): void {
    this.ae2UnitSelectorService.getUnits$()
      .subscribe(units => {
        this.unitGroup = this.ae2UnitSelectorService.createUnitSelectorGroup(units);
        this.changeDetectorRef.markForCheck();
      });
  }

  storeChange(unit: Ae2UnitModel): void {
    this.selectedUnit = unit;
    this.store.dispatch(new Ae2UnitSelectorSetEffectAction({
      unit
    }));
    this.overlayRef.detach();
    this.overlayRef.dispose();
    // this.router.navigateByUrl('/agenda');
    this.router.navigate(['agenda']);

  }


}

@Component({
  selector: 'app-unit-selection-component',
  templateUrl: './unit-selection-buttom.component.html',
  styleUrls: ['./unit-selection.component.sass']
})
export class UnitSelectiotButtomComponent implements OnInit, OnDestroy {

  selectedUnit: Ae2UnitModel;

  private unitSelectorStore$: Observable<Ae2UnitSelectorState>;

  private destroy$: Subject<any> = new Subject();

  @Input() labelInButtom: string;

  constructor(
    private elementRef: ElementRef,
    private overlay: Overlay,
    protected store: Store<any>) {
    this.unitSelectorStore$ = store.pipe(select(ae2UnitSelectorStoreSelector));
  }

  ngOnInit(): void {
    this.unitSelectorStore$
      .pipe(
        takeUntil(this.destroy$),
        filter(state => Boolean(state))
      )
      .subscribe(state => {
        this.selectedUnit = state.unit;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  openPopOver(): void {
    const positionStrategy = this.overlay.position()
      .connectedTo(this.elementRef,
        {
          originX: 'end', originY: 'bottom'
        },
        {
          overlayX: 'end', overlayY: 'top'
        });
    const overlayRef = this.overlay.create({
      positionStrategy: positionStrategy,
      // Aparentemente na beta.10 não funciona a scrollStrategies.close
      // verificar após upgrade
      scrollStrategy: new CustomCloseScrollStrategy(),
      width: this.elementRef.nativeElement.offsetWidth,
      panelClass: 'unit-selector-pane',
      hasBackdrop: true
    });
    overlayRef.backdropClick().subscribe(() => {
      overlayRef.detach();
      overlayRef.dispose();
    });
    const userProfilePortal = new ComponentPortal(UnitSelectorComponent);
    const componentRef: ComponentRef<UnitSelectorComponent> = overlayRef.attach(userProfilePortal);
    componentRef.instance.setData(overlayRef);
  }

}

