import { Routes } from '@angular/router';
import { Ae2AuthRouteGuardService } from '@angularecommerce/core/services/auth-route-guard';
import { AE2_ROUTER_CLASSES_DATA } from '@angularecommerce/core/directives/router-classes';
var ɵ0 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ1 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ2 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ3 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ4 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ5 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ6 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ7 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ8 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ9 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ10 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ11 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ12 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ13 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ14 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ15 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ16 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ17 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ18 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ19 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ20 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ21 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ22 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ23 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ24 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ25 = { someProperty: AE2_ROUTER_CLASSES_DATA };
var routes = [
    {
        path: '',
        loadChildren: 'app/features/home/home.module#HomeModule',
    },
    {
        path: 'categoria/:categoryId',
        loadChildren: 'app/features/products/products.module#ProductsModule',
        data: ɵ0
    },
    {
        path: 'categoria/:categoryId/subcategoria/:subcategoryId',
        loadChildren: 'app/features/products/products.module#ProductsModule',
        data: ɵ1
    },
    {
        path: 'categoria/:categoryId/subcategoria/:subcategoryId/subsubcategoria/:subsubcategoryId',
        loadChildren: 'app/features/products/products.module#ProductsModule',
        data: ɵ2
    },
    {
        path: 'minha-conta',
        canActivate: [Ae2AuthRouteGuardService],
        loadChildren: 'app/features/profile/profile.module#ProfileModule',
        data: ɵ3
    },
    {
        path: 'checkout',
        // canActivate: [Ae2AuthRouteGuardService],
        loadChildren: 'app/features/checkout/checkout.module#CheckoutModule',
        data: ɵ4
    },
    {
        path: 'schedule',
        loadChildren: 'app/features/calendar/calendar.module#CalendarModule',
        data: ɵ5
    },
    {
        path: 'evento/:token',
        loadChildren: 'app/features/event/event.module#EventModule',
        data: ɵ6
    },
    {
        path: 'services',
        loadChildren: 'app/features/list-activities/list-activities.module#ListActivitiesModule',
        data: ɵ7
    },
    {
        path: 'services/:slug/:id',
        loadChildren: 'app/features/activity/activity.module#ActivityModule',
        data: ɵ8
    },
    {
        path: 'services/tickets/:slug/:id',
        loadChildren: 'app/features/activity-ticket/activity-ticket.module#ActivityTicketModule',
        data: ɵ9
    },
    {
        path: 'atividades/:slugatividade/:slugevento/:token',
        loadChildren: 'app/features/event/event.module#EventModule',
        data: ɵ10
    },
    {
        path: 'coaches/:slug/:id',
        loadChildren: 'app/features/instructor/instructor.module#InstructorModule',
        data: ɵ11
    },
    {
        path: 'clientes/resetar-senha/:uidb/:token',
        loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
        data: ɵ12
    },
    {
        path: 'clientes/resetar-senha',
        loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
        data: ɵ13
    },
    {
        path: 'faq',
        loadChildren: 'app/features/faq/faq.module#FaqModule',
        data: ɵ14
    },
    {
        path: 'terms-and-conditions',
        loadChildren: 'app/features/termos/termos.module#TermosModule',
        data: ɵ15
    },
    {
        path: 'privacy-policy',
        loadChildren: 'app/features/policy/policy.module#PolicyModule',
        data: ɵ16
    },
    {
        path: 'depositions',
        loadChildren: 'app/features/testimonials/testimonials.module#TestimonialsModule',
        data: ɵ17
    },
    {
        path: 'coaches',
        loadChildren: 'app/features/instructors/instructors.module#InstructorsModule'
    },
    {
        path: 'units',
        loadChildren: 'app/features/estudios/estudios.module#EstudiosModule'
    },
    {
        path: 'units/:slug/:id',
        loadChildren: 'app/features/estudio/estudio.module#EstudioModule',
        data: ɵ18
    },
    {
        path: 'packs',
        loadChildren: 'app/features/packages/packages.module#PackagesModule',
        data: ɵ19
    },
    {
        path: 'packages',
        loadChildren: 'app/features/packages/packages.module#PackagesModule',
        data: ɵ20
    },
    {
        path: 'about-power',
        loadChildren: 'app/features/a-power/a-power.module#APowerModule',
        data: ɵ21
    },
    {
        path: 'contact',
        loadChildren: 'app/shared/components/contact/contact.module#ContactModule',
        data: ɵ22
    },
    {
        path: 'work-with-us',
        loadChildren: 'app/shared/components/trabalhe-conosco/trabalhe-conosco.module#TrabalheConoscoModule',
        data: ɵ23
    },
    {
        path: 'revalidate',
        loadChildren: 'app/features/re-register/re-register.module#ReRegisterModule',
        data: ɵ24
    },
    {
        path: 'recadastramento',
        loadChildren: 'app/features/re-register/re-register.module#ReRegisterModule',
        data: ɵ25
    },
    { path: '**', pathMatch: 'full', redirectTo: '' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25 };
