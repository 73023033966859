import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { 
  Ae2RouterClassesModule,
  Ae2RouterClassesConfig,
  AE2_ROUTER_CLASSES_CONFIG_TOKEN} from '@angularecommerce/core/directives/router-classes';
import { Ae2HeaderWidgetModule } from '@angularecommerce/core/components/header-widget';
import { Ae2SignInTopModule } from '@angularecommerce/core/components/sign-in-top';
import { Ae2SignUpTopModule } from '@angularecommerce/core/components/sign-up-top';
import { Ae2ForgotPasswordTopModule } from '@angularecommerce/core/components/forgot-password-top';
import { Ae2ForgotPasswordDoneTopModule } from '@angularecommerce/core/components/forgot-password-done-top';
import { Ae2UnitSelectorModule } from '@angularecommerce/core/components/unit-selector';
import { UnitSelectionModule } from '../unit-selection/unit-selection.module';
import { Ae2SiteSettingsModule } from '@angularecommerce/core/components/site-settings';
import { Ae2BlogCategoriesServiceModule } from '@angularecommerce/core/services/blog-categories';
import { MatDividerModule, MatIconModule } from '@angular/material';

export class CustomRouterClassesConfig extends Ae2RouterClassesConfig {
  cssClasses: string[] = ['pagesCustom'];
  fragments: string[] = [
    'login',
    'categoria',
    'minha-conta',
    'checkout',
    'agenda',
    'evento',
    'atividades',
    'instrutor',
    'clientes',
    'faq',
    'termos',
    'politica',
    'depositions',
    'blog',
    'lp',
    'obrigado'
  ];
}
@NgModule({
  imports: [
    MatDividerModule,
    MatIconModule,
    FlexLayoutModule,
    CommonModule,
    RouterModule,
    Ae2SiteSettingsModule,
    Ae2RouterClassesModule,
    Ae2HeaderWidgetModule,
    Ae2SignInTopModule,
    Ae2SignUpTopModule,
    Ae2ForgotPasswordTopModule,
    Ae2ForgotPasswordDoneTopModule,
    Ae2UnitSelectorModule,
    UnitSelectionModule,
    Ae2BlogCategoriesServiceModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  providers: [
    { provide: AE2_ROUTER_CLASSES_CONFIG_TOKEN, useClass: CustomRouterClassesConfig }
  ]
})
export class HeaderModule { }
