var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Ae2HeaderWidgetConfig, Ae2HeaderMenu } from '@angularecommerce/core/components/header-widget';
import { Ae2HeaderLinkToPage, } from '@angularecommerce/core/components/header-widget/header-config';
import { Ae2SignUpStepperConfig, Ae2SignUpField } from '@angularecommerce/core/components/sign-up-steps';
import { Ae2SignUpFieldValidator, Ae2SignupFieldTypes } from '@angularecommerce/core/components/sign-up-steps';
import { Ae2SignUpStepSignup, Ae2SignUpStepAddress, Ae2SignUpStepFitness, Ae2SignUpStepProfile } from '@angularecommerce/core/components/sign-up-steps/sign-up-stepper/sign-up-default-steps.model';
import { OnInit } from '@angular/core';
import { Ae2UnitSelectorConfig } from '@angularecommerce/core/components/unit-selector/unit-selector.model';
import { Router } from '@angular/router';
import { Ae2StorageService } from '@angularecommerce/core/services/cache';
import { ae2SamePasswordValidator } from '@angularecommerce/core/functions/same-password-validator';
import { Ae2BlogCategoriesService, Ae2BlogCategoriesServiceGetAllQuery } from '@angularecommerce/core/services/blog-categories';
var CustomAe2SignUpStepSignup = /** @class */ (function (_super) {
    __extends(CustomAe2SignUpStepSignup, _super);
    function CustomAe2SignUpStepSignup() {
        var _this = _super.call(this) || this;
        _this.fields = [
            new Ae2SignUpField({
                name: 'email',
                label: 'Email',
                validators: new Ae2SignUpFieldValidator({ required: true, validEmail: true, duplicateEmail: true }),
                row: 1,
                size: '48.5%'
            }),
            new Ae2SignUpField({
                name: 'password',
                label: 'Password',
                validators: new Ae2SignUpFieldValidator({ type: Ae2SignupFieldTypes.PASSWORD, required: true, minlength: 6 }),
                row: 2,
                size: '48.5%'
            }),
            new Ae2SignUpField({
                name: 'confirmPassword',
                label: 'Confirm password',
                validators: new Ae2SignUpFieldValidator({ type: Ae2SignupFieldTypes.PASSWORD, required: true, minlength: 6 }),
                row: 2,
                size: '48.5%'
            })
        ];
        _this.syncValidators = ae2SamePasswordValidator('password', 'confirmPassword');
        return _this;
    }
    return CustomAe2SignUpStepSignup;
}(Ae2SignUpStepSignup));
var CustomAe2SignUpStepProfile = /** @class */ (function (_super) {
    __extends(CustomAe2SignUpStepProfile, _super);
    function CustomAe2SignUpStepProfile() {
        var _this = _super.call(this) || this;
        _this.fields = [
            new Ae2SignUpField({
                name: 'firstName',
                internalname: 'first_name',
                label: 'core.signUpSteps.stepper.labels.firstName',
                validators: new Ae2SignUpFieldValidator({ required: true }),
                row: 1,
                size: '31.33%',
                onBlur: function (context) {
                    var firstName = context.stepForm.get('firstName').value;
                    var lastName = context.stepForm.get('lastName').value;
                    var nickname = firstName + " " + lastName;
                    context.stepperConfig.savedData['nickname'] = nickname;
                }
            }),
            new Ae2SignUpField({
                name: 'lastName',
                internalname: 'last_name',
                label: 'core.signUpSteps.stepper.labels.lastName',
                validators: new Ae2SignUpFieldValidator({ required: true }),
                row: 1,
                size: '31.33%',
                onBlur: function (context) {
                    var firstName = context.stepForm.get('firstName').value;
                    var lastName = context.stepForm.get('lastName').value;
                    var nickname = firstName + " " + lastName;
                    context.stepperConfig.savedData['nickname'] = nickname;
                }
            }),
            new Ae2SignUpField({
                name: 'gender',
                label: 'core.signUpSteps.stepper.labels.gender',
                validators: new Ae2SignUpFieldValidator({
                    type: Ae2SignupFieldTypes.SELECT, required: true, options: [
                        { value: 'M', label: 'core.global.male' },
                        { value: 'F', label: 'core.global.female' }
                    ]
                }),
                row: 1,
                size: '31.33%'
            }),
            // new Ae2SignUpField({
            //     name: 'foreignDocument',
            //     label: 'ID',
            //     isVisible: true,
            //     row: 2,
            //     size: '31.33%'
            // }),
            new Ae2SignUpField({
                name: 'dateOfBirth',
                internalname: 'date_of_birth',
                label: 'core.signUpSteps.stepper.labels.dateOfBirth',
                validators: new Ae2SignUpFieldValidator({ required: true, mask: '99/99/9999', minlength: 10 }),
                row: 2,
                size: '31.33%'
            }),
            new Ae2SignUpField({
                name: 'mobilePhone',
                internalname: 'mobile_phone',
                label: 'core.signUpSteps.stepper.labels.mobilePhone',
                validators: new Ae2SignUpFieldValidator({ required: true }),
                row: 2,
                size: '31.33%'
            })
        ];
        return _this;
    }
    return CustomAe2SignUpStepProfile;
}(Ae2SignUpStepProfile));
var CustomAe2SignUpStepAddress = /** @class */ (function (_super) {
    __extends(CustomAe2SignUpStepAddress, _super);
    function CustomAe2SignUpStepAddress() {
        var _this = _super.call(this) || this;
        _this.fields = [
            new Ae2SignUpField({
                name: 'nickname',
                label: 'core.signUpSteps.stepper.labels.nickname',
                validators: new Ae2SignUpFieldValidator({ required: true }),
                row: 1,
                size: '22.75%'
            }),
            new Ae2SignUpField({
                name: 'zip',
                label: 'core.signUpSteps.stepper.labels.zipCode',
                row: 1,
                size: '22.75%'
            }),
            new Ae2SignUpField({
                name: 'address1',
                label: 'core.signUpSteps.stepper.labels.street',
                validators: new Ae2SignUpFieldValidator({ required: true }),
                row: 1,
                size: '22.75%'
            }),
            new Ae2SignUpField({
                name: 'address2',
                label: 'core.signUpSteps.stepper.labels.number',
                validators: new Ae2SignUpFieldValidator({ required: true }),
                row: 1,
                size: '22.75%'
            }),
            new Ae2SignUpField({
                name: 'address3',
                label: 'core.signUpSteps.stepper.labels.complement',
                validators: new Ae2SignUpFieldValidator({ required: false }),
                row: 2,
                size: '22.75%'
            }),
            new Ae2SignUpField({
                name: 'address4',
                label: 'core.signUpSteps.stepper.labels.neighborhood',
                validators: new Ae2SignUpFieldValidator({ required: false }),
                row: 2,
                size: '22.75%'
            }),
            new Ae2SignUpField({
                name: 'city',
                label: 'core.signUpSteps.stepper.labels.city',
                validators: new Ae2SignUpFieldValidator({ required: true }),
                row: 2,
                size: '22.75%'
            }),
            new Ae2SignUpField({
                name: 'state',
                label: 'core.signUpSteps.stepper.labels.state',
                validators: new Ae2SignUpFieldValidator({ required: true }),
                row: 2,
                size: '22.75%'
            })
        ];
        return _this;
    }
    return CustomAe2SignUpStepAddress;
}(Ae2SignUpStepAddress));
export var CustomAe2SignupConfig = new Ae2SignUpStepperConfig([
    new CustomAe2SignUpStepSignup(),
    new CustomAe2SignUpStepProfile(),
    // new CustomAe2SignUpStepAddress(),
    new Ae2SignUpStepFitness()
]);
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(router, ae2BlogCategoriesService, ae2CacheService) {
        this.router = router;
        this.ae2BlogCategoriesService = ae2BlogCategoriesService;
        this.ae2CacheService = ae2CacheService;
        this.headerConfig = new Ae2HeaderWidgetConfig();
        this.unitConfig = new Ae2UnitSelectorConfig();
    }
    HeaderComponent.prototype.ngOnInit = function () {
        this.createSignupConfig();
        this.creatConfig();
        this.headerConfig = new Ae2HeaderWidgetConfig();
        this.headerConfig.menuItems = new Ae2HeaderMenu();
        this.headerConfig.menuItems.items = [
            new Ae2HeaderLinkToPage('ABOUT POWERCYCLE', '/about-power'),
            new Ae2HeaderLinkToPage('COACHES', '/coaches'),
            new Ae2HeaderLinkToPage('STUDIOS', '/units'),
            new Ae2HeaderLinkToPage('SERVICES', '/services'),
            new Ae2HeaderLinkToPage('BUY PACKS', '/packs'),
            new Ae2HeaderLinkToPage('SCHEDULE', '/schedule'),
        ];
        this.headerConfig.backgroundColor = '#ffca00';
    };
    HeaderComponent.prototype.createSignupConfig = function () {
        this.signupConfig = CustomAe2SignupConfig;
    };
    HeaderComponent.prototype.getCategoryBlog = function () {
        var _this = this;
        var data = [];
        var query = new Ae2BlogCategoriesServiceGetAllQuery();
        query.active = true;
        this.ae2BlogCategoriesService.getAll(query)
            .getData$()
            .subscribe(function (res) {
            res.results.map(function (cat) {
                if (cat.name === 'Geral') {
                    data.push(new Ae2HeaderLinkToPage(cat.name, "/blog/categoria/geral/" + cat.id));
                }
                if (cat.name === 'Downloads') {
                    data.push(new Ae2HeaderLinkToPage(cat.name, "/blog/categoria/downloads/" + cat.id));
                    _this.ae2CacheService.set('id_category_downloads', cat.id);
                }
            });
        });
        return data;
    };
    HeaderComponent.prototype.onSelect = function () {
        this.router.navigateByUrl('/agenda');
    };
    HeaderComponent.prototype.creatConfig = function () {
        this.unitConfig.originPos = { originX: 'end', originY: 'bottom' };
        this.unitConfig.overlayPos = { overlayX: 'end', overlayY: 'top' };
    };
    return HeaderComponent;
}());
export { HeaderComponent };
