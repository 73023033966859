import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UnitSelectiotButtomComponent, UnitSelectorComponent } from './unit-selection.component';
import { Ae2UnitSelectorModule } from '@angularecommerce/core/components/unit-selector';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    Ae2UnitSelectorModule,
    FlexLayoutModule
  ],
  declarations: [
    UnitSelectiotButtomComponent,
    UnitSelectorComponent
  ],
  exports: [UnitSelectiotButtomComponent],
  entryComponents: [UnitSelectorComponent]
})
export class UnitSelectionModule { }
