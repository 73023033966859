import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Ae2AuthRouteGuardService, Ae2AuthRouteGuardServiceModule } from '@angularecommerce/core/services/auth-route-guard';
import { AE2_ROUTER_CLASSES_DATA } from '@angularecommerce/core/directives/router-classes';

const routes: Routes = [
  {
    path: '',
    loadChildren: 'app/features/home/home.module#HomeModule',
  },
  {
    path: 'categoria/:categoryId',
    loadChildren: 'app/features/products/products.module#ProductsModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'categoria/:categoryId/subcategoria/:subcategoryId',
    loadChildren: 'app/features/products/products.module#ProductsModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'categoria/:categoryId/subcategoria/:subcategoryId/subsubcategoria/:subsubcategoryId',
    loadChildren: 'app/features/products/products.module#ProductsModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'minha-conta',
    canActivate: [Ae2AuthRouteGuardService],
    loadChildren: 'app/features/profile/profile.module#ProfileModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'checkout',
    // canActivate: [Ae2AuthRouteGuardService],
    loadChildren: 'app/features/checkout/checkout.module#CheckoutModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'schedule',
    loadChildren: 'app/features/calendar/calendar.module#CalendarModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'evento/:token',
    loadChildren: 'app/features/event/event.module#EventModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'services',
    loadChildren: 'app/features/list-activities/list-activities.module#ListActivitiesModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'services/:slug/:id',
    loadChildren: 'app/features/activity/activity.module#ActivityModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'services/tickets/:slug/:id',
    loadChildren: 'app/features/activity-ticket/activity-ticket.module#ActivityTicketModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'atividades/:slugatividade/:slugevento/:token',
    loadChildren: 'app/features/event/event.module#EventModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'coaches/:slug/:id',
    loadChildren: 'app/features/instructor/instructor.module#InstructorModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'clientes/resetar-senha/:uidb/:token',
    loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'clientes/resetar-senha',
    loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'faq',
    loadChildren: 'app/features/faq/faq.module#FaqModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'terms-and-conditions',
    loadChildren: 'app/features/termos/termos.module#TermosModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'privacy-policy',
    loadChildren: 'app/features/policy/policy.module#PolicyModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'depositions',
    loadChildren: 'app/features/testimonials/testimonials.module#TestimonialsModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'coaches',
    loadChildren: 'app/features/instructors/instructors.module#InstructorsModule'
  },
  {
    path: 'units',
    loadChildren: 'app/features/estudios/estudios.module#EstudiosModule'
  },
  {
    path: 'units/:slug/:id',
    loadChildren: 'app/features/estudio/estudio.module#EstudioModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'packs',
    loadChildren: 'app/features/packages/packages.module#PackagesModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'packages',
    loadChildren: 'app/features/packages/packages.module#PackagesModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'about-power',
    loadChildren: 'app/features/a-power/a-power.module#APowerModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'contact',
    loadChildren: 'app/shared/components/contact/contact.module#ContactModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'work-with-us',
    loadChildren: 'app/shared/components/trabalhe-conosco/trabalhe-conosco.module#TrabalheConoscoModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'revalidate',
    loadChildren: 'app/features/re-register/re-register.module#ReRegisterModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'recadastramento',
    loadChildren: 'app/features/re-register/re-register.module#ReRegisterModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
  imports: [
    Ae2AuthRouteGuardServiceModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
