import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { Ae2InstructorModel } from '@angularecommerce/core/services/instructors';
// import { Ae2EventInstructorDetailModel } from '@angularecommerce/core/services/events';
import { Ae2DialogResponse } from '@angularecommerce/core/classes/dialog-response';

@Component({
  selector: 'app-instructor-dialog',
  templateUrl: './instructor-dialog.component.html'
})
export class Ae2InstructorDialogComponent implements OnInit {

  title: string;
  message: string;
  instructor: any;
  instructors: Ae2InstructorModel[];
  okButtonLabel: string;

  constructor(
    private mdDialogRef: MatDialogRef<Ae2InstructorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private mdDialogData: any
  ) { }

  ngOnInit(): void {
    this.title = this.mdDialogData.title;
    this.message = this.mdDialogData.message;
    this.instructor = this.mdDialogData.instructor && this.mdDialogData.instructor.length ? this.mdDialogData.instructor[0] : null;
    this.instructors = this.mdDialogData.instructors;
    this.okButtonLabel = this.mdDialogData.okButtonLabel;
  }

  ok(): void {
    const data = new Ae2DialogResponse();
    data.answer = true;

    this.mdDialogRef.close(data);
  }

}
