<ae2-header-layout-placement-widget></ae2-header-layout-placement-widget>

<ae2-header-widget [config]="headerConfig" [signupConfig]="signupConfig">
    <ng-template #headerMenuTemplate let-menu>
        <div class="ae2-header-menu" fxLayout="row" fxLayoutAlign="end stretch">
            <div *ngFor="let item of menu.items.items" class="ae2-header-menu-item" fxLayout="row" fxLayoutAlign="center stretch">
                <ng-container *ngIf="item.label == 'AGENDA'">
                    <div class="ae2-header-item-page" *ngIf="item.type === 'PAGE'" fxLayout="row" fxLayoutAlign="center stretch">
                        <a fxLayout="row" fxLayoutAlign="center center">
                            <ae2-unit-selector [config]="unitConfig" (select)="onSelect()">
                                <ng-template #labelTemplate>
                                    <span ae2UnitSelectorOpenLayer>{{item.label}}</span>
                                </ng-template>
                            </ae2-unit-selector>
                        </a>
                    </div>

                </ng-container>
                <ng-container *ngIf="item.label != 'AGENDA'">
                    <div class="ae2-header-item-page" *ngIf="item.type === 'PAGE'" fxLayout="row" fxLayoutAlign="center stretch">
                        <a [routerLink]="item.url" fxLayout="row" fxLayoutAlign="center center">
                            <span>{{ item.label }}</span>
                        </a>
                    </div>
                </ng-container>
                <div class="ae2-header-item-session" *ngIf="item.type === 'SESSION'" fxLayout="row" fxLayoutAlign="center stretch">
                    <a (click)="menu.navigator(item.url)" fxLayout="row" fxLayoutAlign="center center">
                        <span>{{ item.label }}</span>
                    </a>
                </div>
                <div class="ae2-header-item-subitems" *ngIf="item.type === 'SUBITEMS'" fxLayout="row" fxLayoutAlign="center stretch">
                    <a (click)="menu.submenu(item.subitems)" fxLayout="row" fxLayoutAlign="center center">
                        <span>{{ item.label }}</span>
                        <mat-icon [class.active]="menu.isSubmenuOpened">keyboard_arrow_down</mat-icon>
                    </a>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #headerSidenavTemplate let-sidenav>
        <div class="ae2-header-sidenav-closer" [class.active]="sidenav.isOpened" (click)="sidenav.closeSidenav()">&nbsp;</div>
        <div class="ae2-header-sidenav" [class.active]="sidenav.isOpened">

            <ng-template #menuItems>

                <ng-container *ngFor="let item of sidenav.items.items">
                    <div *ngIf="['PAGE','SESSION'].indexOf(item.type) >= 0" class="ae2-header-sidenav-item" fxLayout="row" fxLayoutAlign="start center">
                        <div class="ae2-header-sidenav-item-page" *ngIf="item.type === 'PAGE'">
                            <a [routerLink]="item.url" (click)="sidenav.closeSidenav()" class="sidenav-link">
                                <span>{{ item.label }}</span>
                            </a>
                        </div>
                        <div class="ae2-header-sidenav-item-session" *ngIf="item.type === 'SESSION'">
                            <a (click)="sidenav.navigator(item.url); sidenav.closeSidenav()" class="sidenav-link">
                                <span>{{ item.label }}</span>
                            </a>
                        </div>
                    </div>
                </ng-container>

                <mat-divider></mat-divider>
                <br>
                <ng-container *ngIf="sidenav.subitems && sidenav.subitems.length > 0">
                    <div *ngFor="let subitem of sidenav.subitems" class="ae2-header-sidenav-item" fxLayout="row" fxLayoutAlign="start center">
                        <div class="sessionMobile ae2-header-sidenav-item-page" *ngIf="subitem.type === 'PAGE'">
                            <a [routerLink]="subitem.url" (click)="sidenav.closeSidenav()" class="sidenav-link">
                                <span>{{ subitem.label }}</span>
                            </a>
                        </div>
                        <div class="sessionMobile ae2-header-sidenav-item-session" *ngIf="subitem.type === 'SESSION'">
                            <a (click)="sidenav.navigator(subitem.url); sidenav.closeSidenav()" class="sidenav-link">
                                <span>{{ subitem.label }}</span>
                            </a>
                        </div>
                    </div>
                </ng-container>
                <mat-divider></mat-divider>
            </ng-template>

            <ng-container *ngIf="sidenav.isLoggedIn; else notLoggedIn">


                <div fxLayout="column" fxLayoutAlign="start stretch">
                    <div class="ae2-header-sidenav-item" (click)="sidenav.closeSidenav()" routerLink="/minha-conta/meus-dados" fxLayout="row"
                        fxLayoutGap="10px" fxLayoutAlign="start center">
                        <mat-icon>account_circle</mat-icon>
                        <span>Hi, {{(sidenav.authStore$ | async)?.user.firstName || '' }} ({{(sidenav.authStore$ | async)?.totalCredits
                            || 0}} créditos)
                        </span>
                    </div>

                    <ng-template [ngTemplateOutlet]="menuItems"></ng-template>

                    <div class="ae2-header-sidenav-item" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                        <span>My Account</span>
                    </div>
                    <ng-container *ngFor="let item of sidenav.myAccountMenu">
                        <div (click)="sidenav.closeSidenav()" [routerLink]="['/', 'minha-conta', item.link]" class="ae2-header-sidenav-item-myaccount"
                            routerLinkActive="active-link" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                            {{item.name}}
                        </div>
                    </ng-container>

                    <mat-divider></mat-divider>

                    <div class="ae2-header-sidenav-item" (click)="sidenav.logOut(); sidenav.closeSidenav()" fxLayout="row" fxLayoutGap="10px"
                        fxLayoutAlign="start center">
                        <mat-icon>exit_to_app</mat-icon> Logout
                    </div>
                </div>
            </ng-container>

            <ng-template #notLoggedIn>
                <div fxLayout="column" fxLayoutAlign="start stretch">

                    <ng-template [ngTemplateOutlet]="menuItems"></ng-template>

                    <mat-divider></mat-divider>

                    <div class="ae2-header-sidenav-item" (click)="sidenav.closeSidenav(); sidenav.openLogin()" fxLayout="row" fxLayoutGap="10px"
                        fxLayoutAlign="start center">
                        <mat-icon>account_circle</mat-icon>
                        <span>Login</span>
                    </div>
                    <!-- <a fxHide.gt-xs class="ae2-header-sidenav-item" (click)="sidenav.closeSidenav();" href="#" routerLink="/login" fxLayout="row"
                        fxLayoutGap="10px" fxLayoutAlign="start center">
                        <mat-icon>account_circle</mat-icon>
                        <span>Entrar</span>
                    </a> -->

                    <div class="ae2-header-sidenav-item" (click)="sidenav.closeSidenav(); sidenav.openSignup()" fxLayout="row" fxLayoutGap="10px"
                        fxLayoutAlign="start center">
                        <mat-icon>mode_edit</mat-icon>
                        <span>Signup</span>
                    </div>
                    <!-- <a fxHide.gt-xs class="ae2-header-sidenav-item" (click)="sidenav.closeSidenav();" href="#" routerLink="/login" fxLayout="row"
                        fxLayoutGap="10px" fxLayoutAlign="start center">
                        <mat-icon>mode_edit</mat-icon>
                        <span>Inscreva-se</span>
                    </a>           -->

                </div>
            </ng-template>

        </div>
    </ng-template>

</ae2-header-widget>