import { RouterModule } from '@angular/router';
import { Ae2InstructorDialogComponent } from './instructor-dialog.component';
import { Ae2DialogHeaderModule } from '@angularecommerce/core/components/dialog-header';
import { MatDialogModule, MatButtonModule } from '@angular/material';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    RouterModule,
    FlexLayoutModule,
    Ae2DialogHeaderModule
  ],
  declarations: [
    Ae2InstructorDialogComponent
  ],
  entryComponents: [
    Ae2InstructorDialogComponent
  ]
})
export class Ae2InstructorDialogModule { }
