import { Fields, } from '@angularecommerce/core/components/sign-up/sign-up.model';
import { Ae2HeaderWidgetConfig, Ae2HeaderMenu, Ae2HeaderSubmenu } from '@angularecommerce/core/components/header-widget';
import {
    Ae2HeaderLinkToPage,
} from '@angularecommerce/core/components/header-widget/header-config';
import { Ae2SignUpStepperConfig, Ae2SignUpField } from '@angularecommerce/core/components/sign-up-steps';
import { Ae2SignUpFieldValidator, Ae2SignupFieldTypes } from '@angularecommerce/core/components/sign-up-steps';
import {
    Ae2SignUpStepSignup,
    Ae2SignUpStepAddress,
    Ae2SignUpStepFitness,
    Ae2SignUpStepProfile
} from '@angularecommerce/core/components/sign-up-steps/sign-up-stepper/sign-up-default-steps.model';
import { OnInit, Component } from '@angular/core';
import {
    Ae2SelectedUnit,
    Ae2UnitSelectorConfig
} from '@angularecommerce/core/components/unit-selector/unit-selector.model';
import { Router } from '@angular/router';
import { Ae2StorageService } from '@angularecommerce/core/services/cache';
import { ae2SamePasswordValidator } from '@angularecommerce/core/functions/same-password-validator';
import { Ae2BlogCategoriesService, Ae2BlogCategoriesServiceGetAllQuery } from '@angularecommerce/core/services/blog-categories';

class CustomAe2SignUpStepSignup extends Ae2SignUpStepSignup {
    constructor() {
        super();

        this.fields = [
            new Ae2SignUpField({
                name: 'email',
                label: 'Email',
                validators: new Ae2SignUpFieldValidator({ required: true, validEmail: true, duplicateEmail: true }),
                row: 1,
                size: '48.5%'
            }),
            new Ae2SignUpField({
                name: 'password',
                label: 'Password',
                validators: new Ae2SignUpFieldValidator({ type: Ae2SignupFieldTypes.PASSWORD, required: true, minlength: 6 }),
                row: 2,
                size: '48.5%'
            }),
            new Ae2SignUpField({
                name: 'confirmPassword',
                label: 'Confirm password',
                validators: new Ae2SignUpFieldValidator({ type: Ae2SignupFieldTypes.PASSWORD, required: true, minlength: 6 }),
                row: 2,
                size: '48.5%'
            })
        ];

        this.syncValidators = ae2SamePasswordValidator('password', 'confirmPassword');
    }
}

class CustomAe2SignUpStepProfile extends Ae2SignUpStepProfile {
    constructor() {
        super();

        this.fields = [
            new Ae2SignUpField({
                name: 'firstName',
                internalname: 'first_name',
                label: 'core.signUpSteps.stepper.labels.firstName',
                validators: new Ae2SignUpFieldValidator({ required: true }),
                row: 1,
                size: '31.33%',
                onBlur: context => {
                    const firstName = context.stepForm.get('firstName').value;
                    const lastName = context.stepForm.get('lastName').value;
                    const nickname = `${firstName} ${lastName}`;

                    context.stepperConfig.savedData['nickname'] = nickname;
                }
            }),
            new Ae2SignUpField({
                name: 'lastName',
                internalname: 'last_name',
                label: 'core.signUpSteps.stepper.labels.lastName',
                validators: new Ae2SignUpFieldValidator({ required: true }),
                row: 1,
                size: '31.33%',
                onBlur: context => {
                    const firstName = context.stepForm.get('firstName').value;
                    const lastName = context.stepForm.get('lastName').value;
                    const nickname = `${firstName} ${lastName}`;

                    context.stepperConfig.savedData['nickname'] = nickname;
                }
            }),
            new Ae2SignUpField({
                name: 'gender',
                label: 'core.signUpSteps.stepper.labels.gender',
                validators: new Ae2SignUpFieldValidator({
                    type: Ae2SignupFieldTypes.SELECT, required: true, options: [
                        { value: 'M', label: 'core.global.male' },
                        { value: 'F', label: 'core.global.female' }
                    ]
                }),
                row: 1,
                size: '31.33%'
            }),
            // new Ae2SignUpField({
            //     name: 'foreignDocument',
            //     label: 'ID',
            //     isVisible: true,
            //     row: 2,
            //     size: '31.33%'
            // }),
            new Ae2SignUpField({
                name: 'dateOfBirth',
                internalname: 'date_of_birth',
                label: 'core.signUpSteps.stepper.labels.dateOfBirth',
                validators: new Ae2SignUpFieldValidator({ required: true, mask: '99/99/9999', minlength: 10 }),
                row: 2,
                size: '31.33%'
            }),
            new Ae2SignUpField({
                name: 'mobilePhone',
                internalname: 'mobile_phone',
                label: 'core.signUpSteps.stepper.labels.mobilePhone',
                validators: new Ae2SignUpFieldValidator({ required: true }),
                row: 2,
                size: '31.33%'
            })
        ];

    }
}
class CustomAe2SignUpStepAddress extends Ae2SignUpStepAddress {
    constructor() {
        super();

        this.fields = [
            new Ae2SignUpField({
                name: 'nickname',
                label: 'core.signUpSteps.stepper.labels.nickname',
                validators: new Ae2SignUpFieldValidator({ required: true }),
                row: 1,
                size: '22.75%'
            }),
            new Ae2SignUpField({
                name: 'zip',
                label: 'core.signUpSteps.stepper.labels.zipCode',
                row: 1,
                size: '22.75%'
            }),
            new Ae2SignUpField({
                name: 'address1',
                label: 'core.signUpSteps.stepper.labels.street',
                validators: new Ae2SignUpFieldValidator({ required: true }),
                row: 1,
                size: '22.75%'
            }),
            new Ae2SignUpField({
                name: 'address2',
                label: 'core.signUpSteps.stepper.labels.number',
                validators: new Ae2SignUpFieldValidator({ required: true }),
                row: 1,
                size: '22.75%'
            }),
            new Ae2SignUpField({
                name: 'address3',
                label: 'core.signUpSteps.stepper.labels.complement',
                validators: new Ae2SignUpFieldValidator({ required: false }),
                row: 2,
                size: '22.75%'
            }),
            new Ae2SignUpField({
                name: 'address4',
                label: 'core.signUpSteps.stepper.labels.neighborhood',
                validators: new Ae2SignUpFieldValidator({ required: false }),
                row: 2,
                size: '22.75%'
            }),
            new Ae2SignUpField({
                name: 'city',
                label: 'core.signUpSteps.stepper.labels.city',
                validators: new Ae2SignUpFieldValidator({ required: true }),
                row: 2,
                size: '22.75%'
            }),
            new Ae2SignUpField({
                name: 'state',
                label: 'core.signUpSteps.stepper.labels.state',
                validators: new Ae2SignUpFieldValidator({ required: true }),
                row: 2,
                size: '22.75%'
            })
        ];
    }
}

export const CustomAe2SignupConfig = new Ae2SignUpStepperConfig([
    new CustomAe2SignUpStepSignup(),
    new CustomAe2SignUpStepProfile(),
    // new CustomAe2SignUpStepAddress(),
    new Ae2SignUpStepFitness()
]);

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.sass'],
})

export class HeaderComponent implements OnInit {

    headerConfig: Ae2HeaderWidgetConfig = new Ae2HeaderWidgetConfig();
    signupConfig: Ae2SignUpStepperConfig;
    signupFields: Fields;

    unitChange: Ae2SelectedUnit;

    unitConfig: Ae2UnitSelectorConfig = new Ae2UnitSelectorConfig();

    constructor(
        private router: Router,
        private ae2BlogCategoriesService: Ae2BlogCategoriesService,
        private ae2CacheService: Ae2StorageService
    ) {
    }

    ngOnInit(): void {
        this.createSignupConfig();
        this.creatConfig();

        this.headerConfig = new Ae2HeaderWidgetConfig();
        this.headerConfig.menuItems = new Ae2HeaderMenu();
        this.headerConfig.menuItems.items = [
            new Ae2HeaderLinkToPage('ABOUT POWERCYCLE', '/about-power'),
            new Ae2HeaderLinkToPage('COACHES', '/coaches'),
            new Ae2HeaderLinkToPage('STUDIOS', '/units'),
            new Ae2HeaderLinkToPage('SERVICES', '/services'),
            new Ae2HeaderLinkToPage('BUY PACKS', '/packs'),
            new Ae2HeaderLinkToPage('SCHEDULE', '/schedule'),
            // new Ae2HeaderSubmenu('BLOG', this.getCategoryBlog()),
        ];
        this.headerConfig.backgroundColor = '#ffca00';
    }

    createSignupConfig(): void {
        this.signupConfig = CustomAe2SignupConfig;
    }

    getCategoryBlog(): Array<any> {
        const data = [];
        const query: Ae2BlogCategoriesServiceGetAllQuery = new Ae2BlogCategoriesServiceGetAllQuery();
        query.active = true;
        this.ae2BlogCategoriesService.getAll(query)
            .getData$()
            .subscribe(res => {
                res.results.map(cat => {
                    if (cat.name === 'Geral') {
                        data.push(new Ae2HeaderLinkToPage(cat.name, `/blog/categoria/geral/${cat.id}`));
                    }
                    if (cat.name === 'Downloads') {
                        data.push(new Ae2HeaderLinkToPage(cat.name, `/blog/categoria/downloads/${cat.id}`));
                        this.ae2CacheService.set('id_category_downloads', cat.id);
                    }
                });
            });
        return data;
    }

    onSelect(): void {
        this.router.navigateByUrl('/agenda');
    }

    creatConfig(): void {
        this.unitConfig.originPos = { originX: 'end', originY: 'bottom' };
        this.unitConfig.overlayPos = { overlayX: 'end', overlayY: 'top' };
    }
}
