/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unit-selection.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./unit-selection.component";
import * as i4 from "@angular/router";
import * as i5 from "@angularecommerce/core/services/unit-selector/unit-selector.service";
import * as i6 from "@ngrx/store";
import * as i7 from "@angular/cdk/overlay";
var styles_UnitSelectorComponent = [i0.styles];
var RenderType_UnitSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnitSelectorComponent, data: {} });
export { RenderType_UnitSelectorComponent as RenderType_UnitSelectorComponent };
function View_UnitSelectorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.storeChange(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "ae2-unit-selector-unit"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "ae2-unit-selector-unit--selected": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "ae2-unit-selector-unit"; var currVal_1 = _ck(_v, 4, 0, (((_co.selectedUnit == null) ? null : _co.selectedUnit.id) === _v.context.$implicit.id)); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_2); }); }
function View_UnitSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "ae2-unit-selector-location"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "ae2-unit-selector-location-divider": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "ae2-unit-selector-location-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnitSelectorComponent_2)), i1.ɵdid(7, 802816, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = "ae2-unit-selector-location"; var currVal_1 = _ck(_v, 3, 0, !_v.context.last); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.units; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.location.name; _ck(_v, 5, 0, currVal_2); }); }
export function View_UnitSelectorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "ae2-unit-selector"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnitSelectorComponent_1)), i1.ɵdid(3, 802816, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.unitGroup; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_UnitSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-unit-selector", [], null, null, null, View_UnitSelectorComponent_0, RenderType_UnitSelectorComponent)), i1.ɵdid(1, 245760, null, 0, i3.UnitSelectorComponent, [i4.Router, i5.Ae2UnitSelectorService, i6.Store, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnitSelectorComponentNgFactory = i1.ɵccf("app-unit-selector", i3.UnitSelectorComponent, View_UnitSelectorComponent_Host_0, {}, {}, []);
export { UnitSelectorComponentNgFactory as UnitSelectorComponentNgFactory };
var styles_UnitSelectiotButtomComponent = [i0.styles];
var RenderType_UnitSelectiotButtomComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnitSelectiotButtomComponent, data: {} });
export { RenderType_UnitSelectiotButtomComponent as RenderType_UnitSelectiotButtomComponent };
export function View_UnitSelectiotButtomComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "nameUnit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPopOver() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" reservar aulas\n"]))], null, null); }
export function View_UnitSelectiotButtomComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-unit-selection-component", [], null, null, null, View_UnitSelectiotButtomComponent_0, RenderType_UnitSelectiotButtomComponent)), i1.ɵdid(1, 245760, null, 0, i3.UnitSelectiotButtomComponent, [i1.ElementRef, i7.Overlay, i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnitSelectiotButtomComponentNgFactory = i1.ɵccf("app-unit-selection-component", i3.UnitSelectiotButtomComponent, View_UnitSelectiotButtomComponent_Host_0, { labelInButtom: "labelInButtom" }, {}, []);
export { UnitSelectiotButtomComponentNgFactory as UnitSelectiotButtomComponentNgFactory };
