<ng-container>
    <div class="ae2-unit-selector">
        <ng-container *ngFor="let item of unitGroup; last as isLast">
            <div class="ae2-unit-selector-location" [ngClass]="{ 'ae2-unit-selector-location-divider': !isLast }">

                <span class="ae2-unit-selector-location-title"> {{ item.location.name }} </span>

                <ng-container *ngFor="let unit of item.units">
                    <a (click)="storeChange(unit)">
                        <div class="ae2-unit-selector-unit" [ngClass]="{ 'ae2-unit-selector-unit--selected': selectedUnit?.id === unit.id }">
                            <span>{{ unit.name }}</span>
                        </div>
                    </a>
                </ng-container>

            </div>
        </ng-container>
    </div>

</ng-container>