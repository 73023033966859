import { Ae2InstructorDialogModule } from './shared/components/instructor-dialog/instructor-dialog.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, isDevMode } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppScrollWatcherServiceModule } from './shared/services/scroll-watcher/scroll-watcher.module';
import { Ae2MyAccountGlobalConfig, AE2_MY_ACCOUNT_CONFIG_TOKEN, MenuInterface } from '@angularecommerce/core/components/my-account';
import { Ae2CoreModule } from '@angularecommerce/core/modules/core';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { Ae2AuthStoreModule } from '@angularecommerce/core/stores/auth';
import { Ae2HeaderStoreModule } from '@angularecommerce/core/stores/header';
import { Ae2UnitSelectorStoreModule } from '@angularecommerce/core/stores/unit-selector';
import { Ae2AuthVerificationInitializerModule } from '@angularecommerce/core/modules/auth-verification-initializer';
import { Ae2UnitSelectorInitializerModule } from '@angularecommerce/core/modules/unit-selector-initializer';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderModule } from './shared/components/header/header.module';
import { FooterModule } from './shared/components/footer/footer.module';

export class MyAccountCustomConfig extends Ae2MyAccountGlobalConfig {

  showFTP: boolean = false;
  showCpf: boolean = false;
  autoCompleteCep: boolean = false;


  menu: MenuInterface[] = [
    { name: 'My Profile', link: 'meus-dados', icon: 'account_circle' },
    { name: 'My Credits', link: 'meus-creditos', icon: 'account_balance' },
    { name: 'My Classes', link: 'meus-eventos', icon: 'event' },
    { name: 'My Orders', link: 'meus-pedidos', icon: 'bookmark_border' },
    { name: 'My Subscriptions', link: 'minhas-assinaturas', icon: 'bookmark' },
    { name: 'My Address', link: 'meus-enderecos', icon: 'room' },
    { name: 'Creditcards', link: 'metodos-pagamento', icon: 'credit_card' },
    { name: 'Autobook', link: 'meus-eventos-automaticos', icon: 'event' },
    { name: 'Sharing Credits', link: 'compartilhar-creditos', icon: 'share' },
    { name: 'Performance Data', link: 'dados-de-performance', icon: 'timeline' },
    { name: 'Change Password', link: 'alterar-senha', icon: 'lock' },
  ];

}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,
    CommonModule,

    HeaderModule,
    FooterModule,

    RouterModule,

    AppScrollWatcherServiceModule,
    Ae2InstructorDialogModule,

    AppRoutingModule,

    Ae2CoreModule,

    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),

    /**
     * Remova esse módulo se não estiver usando sistema de autenticação
     */
    Ae2AuthStoreModule,

    /**
     * Remova esses módulos se não estiver usando header-widget no site
     */
    Ae2HeaderStoreModule,
    Ae2UnitSelectorStoreModule,

    Ae2AuthVerificationInitializerModule,
    Ae2UnitSelectorInitializerModule
  ],
  providers: [
    { provide: AE2_MY_ACCOUNT_CONFIG_TOKEN, useClass: MyAccountCustomConfig }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
